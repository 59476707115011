.location-style{
    margin-bottom: 15px;
    line-height: 1.8;
    color: #656565;
    font-weight: 500;
    font-family: 'Helvetica Neue', 'Raleway', sans-serif;
    font-size: 16px;
}

.title-underline {
    width: 68px;
    border: 1px solid #000000;
    margin: 0 auto 40px auto;
}

.input-field {
    position: relative;
    margin-top: 15px;
    margin-bottom: 0!important;
}

.question-form {
    display: block;
    width: 100%;
    height: 156px;
    border: 1px solid #ffffff;
    background-color: #ffffff;
    padding: 6px 10px;
    border-radius: 2px;
    outline: 0;
    box-shadow: unset;
}
.table-view{
    border: none!important;
    border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.question-form  {
    display: block;
    width: 100%;
    height: 42px;
    border: 1px solid #ffffff;
    background-color: #ffffff;
    padding: 6px 10px;
    border-radius: 2px;
    outline: 0;
    box-shadow: unset;
}

.submit-btn {
    padding: 10px 30px !important;
    background: #f5c014 !important;
    border-radius: 20px !important;
    color: #fff !important;
    text-align: center !important;
    border: none !important;
}
.errorMsgColour{
    color: red;
}

.reload-btn{
    font-size: 18px;
    padding: 10px 10px;
    background: #f5c014 ;
    border-radius: 20px ;
    color: #fff ;
    text-align: center ;
    border: none;
}

.map-section{
    margin-top: 20px;
}