.header-bg {
    background: #f5c014;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 1em;
}

.logo {
    width: 50px;
    height: auto;
    margin-left: 1em;
}

.navbar-light .navbar-toggler {
    color: #f5c014;
    border-color: #f5c014;
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: #f5c014;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: box-shadow .15s ease-in-out;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    color: #f5c014;
    background: #f5c014;
}

.header-text {
    color: #faaf47 !important;
    margin-right: 3em;
    white-space: nowrap;
}

.header-text-wt {
    color: #ffff !important;
    margin-right: 3em;
    white-space: nowrap;
    font-weight: bold
}

.vertical-line {
    border-left: 1px solid #ffff;
    height: 14px;
    margin-right: 1.5em;
    align-self: center;
}

.header-icon {
    color: #ffff;
    margin-right: 0.3em;
    cursor: pointer;
}

.header-text,
.header-text-wt {
  color: #333; /* Default color */
  transition: color 0.3s ease;
}

.header-text.active,
.header-text-wt.active {
  color: #ffff; /* Active color */
  font-weight: bold; /* Optional: makes the active link bold */
  border-bottom: 2px solid #ffff; /* Optional: adds an underline for active link */
}


/* Default (non-active) link styles */
.header-text,
.header-text-wt {
    color: #ffffff !important; /* White for non-active links */
    margin-right: 3em;
    white-space: nowrap;
}

/* Active state styling */
.navbar-nav .nav-link.active {
    color: #ffff !important; /* Active link color - yellow */
    font-weight: bold; /* Optional: make it bold */
}

.header-text.active, 
.header-text-wt.active {
    color: #ffff !important; /* Yellow for active link */
}

/* Other existing styles */

.profile-dsgn{
    width: 25px;
    border-radius: 15px;
    cursor: pointer;
}

.cursor{
    cursor: pointer;
}

@media (max-width: 992px) {

    .header-text,
    .header-text-wt {
        margin-right: 1em;
        font-size: 16px;
    }

    .vertical-line {
        margin-right: 1em;
    }
}

@media (max-width: 812px) {

    .header-text,
    .header-text-wt {
        margin-right: 1em;
        font-size: 14px;
    }

    .vertical-line {
        margin-right: 0.5em;
    }
}

@media (max-width: 768px) {
    .vertical-line {
        display: none !important;
    }

    .header-icon{
        font-size: 17px !important;
    }
}